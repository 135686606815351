import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';
import Link from 'components/ui/link';

import cover from 'img/blog/b2b-channels/image3.jpg';
import image1 from 'img/blog/b2b-channels/image1.jpg';
import image2 from 'img/blog/b2b-channels/image2.jpg';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        Both sales and marketing teams are working towards the same goal: to make a company grow and
        to generate more profit. Unfortunately, the reality of many companies is that these two
        teams work separately, and the communication between them is questionable.
      </p>
      <br />

      <p>
        The lack of collaboration between these two teams has always been harmful to both clients
        and the company itself, but now it becomes more problematic than ever. Basically, customers
        lots of touch points before deciding to purchase. On average, we’re talking about between{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.salesforce.com/blog/2015/04/takes-6-8-touches-generate-viable-sales-lead-heres-why-gp.html"
        >
          6 and 8 points
        </Link>{' '}
        of contact with a brand - and some of them related to sales, whereas others belong to the
        marketing.
      </p>
      <br />
      <p>
        When you think about it: Customers visit a company’s website, follow the brand on various
        social media channels, see outdoor ads, subscribe to newsletters, read opinions about the
        product, ask their friends’ opinions on social media, contact a salesperson… And the list
        goes on. Luckily, most of these touchpoints can be used for{' '}
        <a
          href="https://wiza.co/blog/how-to-export-leads-from-linkedin-sales-navigator/"
          rel="noopener noreferrer"
          target="_blank"
        >
          lead generation
        </a>
        .
      </p>
      <br />

      <h2 className="mb-5">Lead generation explained</h2>
      <p>
        Lead is usually a person that has already expressed some interest in your product by sharing
        his or her contact information. There’s a distinction, though: Cold leads are people who are
        only interested in an offer (for example an ebook) and have no intention to buy a product.
        Hot leads, on the other hand, are almost ready to buy as they follow the brand on social
        media, subscribe to the newsletter, regularly visit the website. All of them can be nurtured
        and encouraged to finalize the transaction.
      </p>
      <br />
      <p>
        A lead generation goes far beyond having people signed up for newsletters and sending them
        emails: marketers can use landing pages, webinars, podcasts, blogs, video marketing, social
        media, events, just to name a few.
      </p>
      <br />
      <p>
        Lead generation is not rocket science but at the same time,{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.hubspot.com/marketing-statistics"
        >
          65% of businesses
        </Link>{' '}
        say that generating traffic and leads is their biggest marketing challenge. Using all the
        touchpoints to meet customers seems to require a plethora of work and resources. On the
        other hand, almost every company has some of the touchpoints ready - usually a website and a
        few social media profiles that they maintain. Optimizing them for lead generation can bring
        your business much more benefits than you might think. Provided that you do it right, that
        is.
      </p>
      <br />

      <h2>How to optimize your own website and social media profiles for lead generation?</h2>
      <h3>1. Make your website a central hub for content marketing</h3>
      <p>
        The majority of B2B companies claim that{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://blog.marketo.com/2015/08/data-talks-2-proven-lead-generation-tactics-to-jump-on-now.html"
        >
          content marketing generates more leads
        </Link>{' '}
        than other marketing strategies. Having engaging and persuasive content on the website
        should be a starting point for lead generation and the website should work as the hub for
        all content marketing efforts. It’s a good and common practice to include a link to the
        website in all the marketing materials, both online and printed. What is more,
        third-parties, such as review sites, social media,{' '}
        <Link href="https://storyxpress.co/video-hosting" rel="noopener noreferrer" target="_blank">
          video hosting
        </Link>{' '}
        sites, can also link to the website and bring leads. Therefore, it’s crucial to have a site
        that is rich in informative, valuable content and that is easy to navigate.
      </p>
      <br />
      <img
        src={image1}
        className="img-fluid justify-content-center mb-4"
        alt="channels"
        loading="lazy"
        title="Content and webdesign"
      />
      <p className="text-center" style={{ margin: '-1rem 0 2rem 0', fontSize: '.7rem' }}>
        Image by{' '}
        <Link rel="noopener nofollow" target="_blank" href="https://rawpixel.com">
          rawpixel.com
        </Link>
      </p>

      <h3>2. Optimise your website for lead generation</h3>
      <p>
        Once you have such content, you can easily use it for lead generation. Of course, you can
        publish a long and informative ebook or whitepaper for free and let everyone download it
        within 2 clicks. But you can also ask for a piece of contact information instead. The most
        popular way is to ask for a name and email, and then send an ebook directly to someone’s
        mailbox. There’s also another: ask consumers to ‘pay’ for your content by sharing it with
        their network. It requires less effort than filling the form (and let’s be frank, everybody
        hates online forms) and makes everyone an advocate of your brand.
      </p>
      <br />

      <h3>3. Don’t neglect the User Experience</h3>
      <p>
        Speaking of optimising your website - it needs to be user-friendly in order to convert leads
        into customers. In other words: When prospects land on the website, their experience has to
        be seamless. If they have to wait for images to load and struggle to navigate or find
        relevant information within a few clicks, they’re very likely to go somewhere else. Make
        sure that your visitors don’t get stuck when browsing your website and they’re “landing”
        exactly where you need them to be. Record how a user interacts with your website, analyze
        their behaviour and draw conclusions from it to improve the experience. You can easily do so
        with{' '}
        <Link
          rel="noopener"
          target="_blank"
          href="https://albacross.com/newsroom/convert-prospects-into-customers/"
        >
          convert leads into customers
        </Link>
        . In other words: When prospects land on the website, their experience has to be seamless.
        If they have to wait for images to load and struggle to navigate or find relevant
        information within a few clicks, they’re very likely to go somewhere else. Make sure that
        your visitors don’t get stuck when browsing your website and they’re “landing” exactly where
        you need them to be. Record how a user interacts with your website, analyze their behaviour
        and draw conclusions from it to improve the experience. You can easily do so with{' '}
        <Link
          rel="noopener"
          target="_blank"
          href="/signup?utm_source=blog&utm_campaign=how-to-use-the-owned-channels-to-generate-b2b-leads-and-boost-sales"
        >
          LiveSession
        </Link>
        .
      </p>
      <br />

      <h3>4. Have a multichannel approach to social media</h3>
      <p>
        Studies show that people are 71% more likely to purchase something{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://blog.hubspot.com/blog/tabid/6307/bid/30239/71-More-Likely-to-Purchase-Based-on-Social-Media-Referrals-Infographic.aspx"
        >
          based on a social media referral
        </Link>
        . However, to generate leads using social media, you need to be where your audience is. And
        it’s unlikely to be one platform since, on average, internet users have{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.brandwatch.com/blog/amazing-social-media-statistics-and-facts/"
        >
          7.6 social media accounts
        </Link>
        . Managing so many profiles at work is not a piece of cake, but{' '}
        <Link rel="noopener noreferrer" target="_blank" href="https://instasize.com/">
          the right tools
        </Link>{' '}
        come to the rescue. A good example is{' '}
        <Link rel="noopener noreferrer" target="_blank" href="https://www.kontentino.com/">
          Kontentino
        </Link>
        , a{' '}
        <a href="https://bulk.ly/social-media-tools/" rel="noopener noreferrer" target="_blank">
          social media management tool
        </a>
        . It’s more than a simple calendar that allows you to schedule posts but also a{' '}
        <a href="https://niftypm.com/" rel="noopener noreferrer" target="_blank">
          project management software
        </a>{' '}
        to facilitate the work of a social media team. Omnichannel presence also requires
        omnichannel content. You can’t prepare one copy and share it on every platform - not only
        because Twitter has a character limit but because every channel has its specifics.
      </p>
      <br />

      <img
        src={image2}
        className="img-fluid justify-content-center mb-4"
        alt="channel"
        loading="lazy"
        title="Social media"
      />
      <p className="text-center" style={{ margin: '-1rem 0 2rem 0', fontSize: '.7rem' }}>
        Image by{' '}
        <Link rel="noopener nofollow noreferrer" target="_blank" href="https://rawpixel.com">
          rawpixel.com
        </Link>
      </p>

      <h3>5. Get the best out of social media advertising</h3>
      <p>
        It’s a common practice to boost social media marketing with paid campaigns as organic
        results are always limited, especially if you’ve started only recently. Social media ads can
        bring good results even with small budgets, and there are many options to choose from:
        boosting posts, promoting events or content, creating ads dedicated to lead generation, and
        more. If you use a service like{' '}
        <Link rel="noopener noreferrer" target="_blank" href="https://rocketlink.io">
          RocketLink
        </Link>
        , you’ll be able to target social media campaigns to people who have clicked a specific
        link. In short, you will create your own custom audience.
      </p>
      <br />

      <h2>Make the best out of your owned channels</h2>
      <p>
        Social media and content marketing, especially if published on a quality website, are
        inseparable and perfect for engaging an audience that later can turn into clients. At the
        same time, marketers constantly look for new ways of engaging the audience and to boost
        traffic. There is nothing wrong with that, quite the contrary, but let’s not forget and
        ignore what we already have: Every new brand starts with a website and at least 2 or 3
        social media profiles anyway. With the right optimization and engaging content, both can
        play a crucial role in lead generation and boosting sales.{' '}
      </p>
      <br />
      <p>
        The clue is to keep this engagement, build the trust towards the brand, and offer
        outstanding user experience. Only then visitors become leads and leads become paying
        clients.{' '}
      </p>
      <br />

      <p>
        Get valuable insights on how visitors interact with your website and improve conversions.{' '}
        <strong>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="/signup?utm_source=blog&utm_campaign=how-to-use-the-owned-channels-to-generate-b2b-leads-and-boost-sales"
          >
            Try LiveSession now.
          </Link>
        </strong>
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'How to use the owned channels to generate B2B leads and boost sales',
  url: '/blog/how-to-use-the-owned-channels-to-generate-b2b-leads-and-boost-sales/',
  description: `Both sales and marketing teams are working towards the same goal: to
  make a company grow and to generate more profit.`,
  author,
  img: cover,
  date: '2019-06-19',
  category: 'growth',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial="Both sales and marketing teams are working towards the same goal: to make a company grow and to generate more profit. Unfortunately, the reality of many companies is that these two teams work separately, and the communication between them is questionable."
    img={frontmatter.img}
  />
);
